<template>
  <div>
    <v-list-item
      rounded
      class="text-left pa-3 ma-2"
      @click="showDetails = !showDetails"
    >
      <v-list-item-title>
        {{ object.title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        <EnumDisplayLanguage
          enum-name="objectType"
          :enum-item="object.objectType"
        />
      </v-list-item-subtitle>

      <template #append>
        <div class="d-flex align-center gap-2">
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-chip
                class="mr-2"
                color="green"
                variant="outlined"
                v-bind="props"
              >
                <ReadableNumber :number="object.expenctedRefferalFee" /> €
              </v-chip>
            </template>
            {{ $t("object.refferalFee") }}
          </v-tooltip>

          <v-chip color="warning">
            <span v-if="object.progressStep">
              <EnumDisplayLanguage
                enum-name="gkProcess"
                :enum-item="object.progressStep"
              />
            </span>
            <span v-else>{{ $t("enum.pending") }}</span>
          </v-chip>
        </div>
      </template>
    </v-list-item>

    <!-- Divider nur anzeigen, wenn es nicht das letzte Element ist -->
    <v-divider v-if="!isLast" class="mx-4" />
  </div>

  <v-dialog v-model="showDetails">
    <GKObjectItemDetails
      @updatedStep="
        (step) => {
          $emit('updatedStep', {
            objectId: object.id,
            step: step,
          });
        }
      "
      :object="object"
    />
    <v-btn
      icon="mdi-close"
      flat
      variant="plain"
      color="grey"
      class="position-absolute top-0 left-0"
      @click="showDetails = false"
    />
  </v-dialog>
</template>

<script>
import ReadableNumber from "@/components/helpers/ReadableNumber.vue";
import GKObjectItemDetails from "./GKObjectItemDetails.vue";
import EnumDisplayLanguage from "@/components/helpers/EnumDisplayLanguage.vue";
import EGkProgressStep from "@/enums/EGkProgressSteps";

export default {
  name: "GKObjectListItem",
  components: {
    EnumDisplayLanguage,
    ReadableNumber,
    GKObjectItemDetails,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      steps: EGkProgressStep,
      showDetails: false,
    };
  },
};
</script>

<style scoped>
.v-list-item {
  cursor: pointer;
  transition: background-color 0.2s;
}
.v-list-item:hover {
  background-color: rgba(204, 168, 118, 0.1);
}
</style>
