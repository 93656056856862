<template>
  <v-card flat>
    <v-carousel
      v-if="images && images.length > 0"
      height="200"
      width="auto"
      hide-delimiters
    >
      <v-carousel-item
        v-for="(image, index) in formattedImages"
        :key="index"
        :src="image"
        rounded
        cover
        class="carousel-item-container position-relative"
      >
        <div
          class="position-absolute d-flex justify-center mt-1 align-top"
          style="width: 100%; height: 100%"
        >
          <v-btn
            class="me-1"
            density="comfortable"
            flat
            icon="mdi-fullscreen"
            @click="showDialog = true"
          >
          </v-btn>
          <v-btn
            v-if="canEdit"
            density="comfortable"
            icon
            flat
            @click="$emit('delete-image', index)"
          >
            <v-icon color="error">mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </v-carousel-item>
    </v-carousel>
    <v-card-text v-else class="my-0 py-0 text-grey">
      {{ $t("noData.default") }}
    </v-card-text>

    <!-- Fullscreen Dialog -->
    <v-dialog
      width="98%"
      height="90%"
      v-model="showDialog"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-carousel
          v-if="images && images.length > 0"
          height="calc(100vh - 64px)"
          hide-delimiter-background
          show-arrows="hover"
          color="accent"
        >
          <v-carousel-item
            v-for="(image, index) in formattedImages"
            :key="index"
            :src="image"
            class="fullscreen-image"
          >
          </v-carousel-item>
        </v-carousel>
        <v-btn
          class="position-absolute top-0 right-0"
          variant="text"
          flat
          icon="mdi-close"
          @click="showDialog = false"
        >
        </v-btn>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: "GKObjectImageCarousel",
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    formattedImages() {
      return this.images.map((img) => {
        // Wenn das Bild bereits ein data:image Format hat
        if (typeof img === "string" && img.startsWith("data:image")) {
          return img;
        }
        // Wenn es ein File-Objekt ist
        if (img.file && typeof img.file === "string") {
          if (img.file.startsWith("data:image")) {
            return img.file;
          }
          return `data:image/jpeg;base64,${img.file}`;
        }
        // Fallback für base64 Strings
        return `data:image/jpeg;base64,${img}`;
      });
    },
  },
};
</script>

<style scoped>
.v-carousel {
  border-radius: 4px;
}

.carousel-item-container {
  position: relative;
}

.expand-button {
  position: absolute !important;
  top: 0px;
  right: 12px;
  z-index: 1;
  background: rgba(0, 0, 0, 0.3);
  color: white;
}

.expand-button:hover {
  background: rgba(0, 0, 0, 0.5);
}

.fullscreen-image :deep(img) {
  object-fit: contain;
  max-height: calc(100vh - 64px);
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container :deep(img) {
  max-width: auto;
  max-height: 200px;
  width: auto;
  height: auto;
  object-fit: contain;
}
</style>
