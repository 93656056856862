import authHeader from "@/services/auth-header";
import axios from "axios";
import host from "./host";
import { useAuthUserStore } from "@/stores/modules/auth.module";
import router from "@/router";
import { useUserProgressStore } from "@/stores/progress.module";
import { useNotificationStore } from "@/stores/notification.module";
import NotificationElephant from "@/models/notification";
const API_URL = host + "/api/";
class ApiService {
  get(url) {
    let self = this;

    const authUserStore = useAuthUserStore();
    if (!authUserStore) {
      return new Promise(() => {
        return null;
      });
    }
    return axios
      .get(API_URL + url, { headers: authHeader() })
      .then((response) => {
        const progressStore = useUserProgressStore();
        progressStore.getProgressWithoutApiService();
        return response;
      })
      .catch(function (error) {
        if (error.response) {
          self.handleElephantError(error.response);
        }
      });
  }

  put(url, data) {
    let self = this;
    const authUserStore = useAuthUserStore();
    if (!authUserStore) {
      return new Promise(() => {
        return null;
      });
    }
    return axios
      .put(API_URL + url, data, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (error.response) {
          self.handleElephantError(error.response);
        }
      });
  }

  handleElephantError(response) {
    if (response.data?.show) {
      const notificationStore = useNotificationStore();
      let data = response.data;
      notificationStore.push(
        new NotificationElephant({
          title: data.title,
          message: data.message,
          color: "ERROR",
        })
      );
    }
    if (response.status === 401) {
      const authStore = useAuthUserStore();
      console.log("Unauthorisierter Zugriff", response);
      authStore.logout();
      router.push("/login");
    }
  }
  getUnauthorized(url) {
    return axios.get(API_URL + url);
  }
  postUnauthorized(url, data) {
    return axios.post(API_URL + url, data);
  }

  post(url, data) {
    let self = this;
    // eslint-disable-next-line no-console
    //console.log(API_URL+url+" Data "+data+"  HEader"+authHeader());

    const authUserStore = useAuthUserStore();
    if (!authUserStore) {
      console.log("AuthStore is empty");
      return new Promise(() => {
        return null;
      });
    }
    return axios
      .post(API_URL + url, data, { headers: authHeader() })
      .then((response) => {
        const progressStore = useUserProgressStore();
        progressStore.getProgressWithoutApiService();
        return response;
      })
      .catch(function (error) {
        if (error.response) {
          self.handleElephantError(error.response);
        }
      });
  }
  async getBetter(url) {
    const authUserStore = useAuthUserStore();
    if (!authUserStore) {
      return Promise.resolve(null);
    }

    try {
      const response = await axios.get(API_URL + url, {headers: authHeader()});
      const progressStore = useUserProgressStore();
      progressStore.getProgressWithoutApiService();
      return response;
    } catch (error) {
      if (error.response) {
        this.handleElephantError(error.response);
      }
      throw error;
    }
  }
  postWithAdditionalHeader(url, data, header) {
    let self = this;
    // eslint-disable-next-line no-console
    //console.log(API_URL+url+" Data "+data+"  HEader"+authHeader());
    return axios
      .post(API_URL + url, data, { headers: authHeader(), header })
      .then((response) => {
        const progressStore = useUserProgressStore();
        progressStore.getProgressWithoutApiService();
        return response;
      })
      .catch(function (error) {
        if (error.response) {
          self.handleElephantError(error.response);
        }
      });
  }
  callOpenStreetMapApi(query) {
    return axios.get(
      "https://nominatim.openstreetmap.org/?addressdetails=1&q=" +
        query +
        "&format=json&limit=3"
    );
  }
  async checkServerState() {
    let response = await axios
      .get(host + "/actuator/health")
      .catch(function (error) {
        if (error.response) {
          const notificationStore = useNotificationStore();
          notificationStore.push(
            new NotificationElephant({
              title: "Keine Verbindung mit dem Server möglich",
              message:
                "Der Server ist entweder offline oder deine Verbindung ist schlecht.",
              color: "ERROR",
            })
          );

          return false;
        }
      });
    if (response && response.data && response.data.status === "UP") return true;
    else {
      const notificationStore = useNotificationStore();
      notificationStore.push(
        new NotificationElephant({
          title: "Keine Verbindung mit dem Server möglich",
          message: "Der Server ist offline.",
          color: "ERROR",
        })
      );

      return false;
    }
  }
}

export default new ApiService();
