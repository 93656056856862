<template>
  <v-sheet
    class="hover-sheet px-5 py-5 text-left"
    rounded
    style="position: relative; overflow: hidden"
    @click="showDetails = !showDetails"
  >
    <v-img
        cover
      v-if="object.images && object.images.length > 0"
      :src="'data:image/webp;base64,'+object.images[0].file"
      style="pointer-events: none"
      class="background-image gradient-background"
      height="100%"
    />
      <v-img class="background-image gradient-background"
        v-else
        width="100%"
        height="100%"
        src="https://plus.unsplash.com/premium_photo-1680281936362-aff258ecd143?q=80&w=3100&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        cover
      ></v-img>
    <v-list-item class="pa-0 mb-4 text-left" :title="object.title">
      <template #subtitle>
        <EnumDisplayLanguage
          enum-name="objectType"
          :enum-item="object.objectType"
        />
      </template>
    </v-list-item>

    <v-list
      color="transparent"
      rounded
      class="bg-transparent pa-0 my-1"
      max-height="100px"
    >
      <!-- <v-list-item
        disabled
        v-if="object.todoItemList.length <= 0"
        :title="$t('noData.small', { obj: $t('tasks',object.todoItemList.length) })"
      ></v-list-item>
      <v-list-item
        density="compact"
        v-for="task in object.todoItemList"
        :key="task.id"
        @click="task.done = !task.done"
      >
        <template v-slot:prepend>
          <v-checkbox-btn v-model="task.done" color="grey"></v-checkbox-btn>
        </template>
        <v-list-item-title>
          <span :class="task.done ? 'text-grey' : 'text-grey'">{{
            task.text
          }}</span>
        </v-list-item-title>
        <template v-slot:append>
          <v-expand-x-transition>
            <v-icon v-if="task.done" color="success"> mdi-check </v-icon>
          </v-expand-x-transition>
        </template>
      </v-list-item> -->
      <v-tooltip bottom>
        <template #activator="{ props }">
          <v-chip color="green" variant="tonal" v-bind="props">
            <ReadableNumber :number="object.expenctedRefferalFee" /> €
          </v-chip>
        </template>
        {{ $t("calculatedReferralFee") }}
      </v-tooltip>
      <v-chip color="warning" variant="tonal" class="mx-2">
        <span v-if="object.progressStep"
          ><EnumDisplayLanguage
            enum-name="gkProcess"
            :enum-item="object.progressStep"
        /></span>
        <span v-else>{{ $t("enum.pending") }}</span>
      </v-chip>
    </v-list>
  </v-sheet>
  <v-dialog v-model="showDetails">
    <GKObjectItemDetails
      @updatedStep="
        (step) => {
          this.$emit('updatedStep', {
            objectId: this.object.id,
            step: step,
          });
        }
      "
      :object="object"
    />
    <v-btn
      icon="mdi-close"
      flat
      variant="plain"
      color="grey"
      class="position-absolute top-0 right-0"
      @click="showDetails = false"
    />
  </v-dialog>
</template>

<script>
import ReadableNumber from "@/components/helpers/ReadableNumber.vue";
import DateDisplay from "@/components/helpers/DateDisplay.vue";
import UserAvatar from "@/components/user/UserAvatar.vue";
import GKObjectItemDetails from "./GKObjectItemDetails.vue";
import EnumDisplay from "@/components/helpers/EnumDisplay.vue";
import EGkProgressStep from "@/enums/EGkProgressSteps";
import EnumDisplayLanguage from "@/components/helpers/EnumDisplayLanguage.vue";
export default {
  name: "GKObjectItem",
  components: {
    EnumDisplayLanguage,
    EnumDisplay,
    UserAvatar,
    DateDisplay,
    ReadableNumber,
    GKObjectItemDetails,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      steps: EGkProgressStep,
      showDetails: false,
    };
  },
  computed: {
    address() {
      return `${this.object.address.postcode} ${this.object.address.city} ${this.object.address.street}`;
    },
    sortedProgressData() {
      return [...this.progressData].sort((a, b) => a.stepIndex - b.stepIndex);
    },
  },
};
</script>

<style scoped>
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}
.steps {
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.steps div:nth-child(odd) {
  opacity: 0.2;
}
.hover-sheet {
  cursor: pointer;
  box-sizing: border-box!important;
}
.hover-sheet:hover {
  outline: 1px solid #cca876;
}
</style>
