<template>
  <v-card rounded class="pa-5 d-flex rounded-xl flex-wrap justify-space-between" color="blue" width="200px"
          min-height="90px" elevation="0">

    <div class="text-white text-left">{{$t('object.slots.locked')}}
      <v-icon size="x-small" icon="mdi-lock"></v-icon>
    </div>
    <div class="d-flex align-end">
      <v-btn variant="tonal" color="success" block @click="$emit('unlock')" size="small">{{$t('object.slots.unlock')}}</v-btn>
    </div>
  </v-card>
</template>
<script>
export default {
  name: 'LockedSlot',
  emits: ['unlock'],
}
</script>