<script>
import Hotjar from "@hotjar/browser";
export default {
  name: "HotjarImplementation",
  mounted() {
    const siteId= process.env.VUE_APP_HOTJAR_SITE_ID;
    if(!siteId){
      console.log("Hotjar: Site ID not found");
      return;
    }
    let hotjarVersion= process.env.VUE_APP_HOTJAR_VERSION;
    let isProduction = process.env.VUE_APP_IS_PRODUCTION==="true";
    if(isProduction) {
      console.log("Hotjar: Loading Hotjar in production mode");
      try{
        Hotjar.init(siteId, hotjarVersion);
      }catch (e) {
        console.error("Hotjar: Error loading Hotjar", e);
      }
    }
  }
}
</script>

<template>

</template>

<style scoped>

</style>