<script>
import ApiService from "@/services/api.service";
import { debounce } from "lodash";

export default {
  name: "PaginationWrapper",
  data() {
    return {
      items: [],
      page: 1,
      totalPages: 0,
      totalItems: 0,
      loading: false,
      query: "",
      debouncedLoadPage: null,
    };
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    url: {
      type: String,
      required: true,
    },
  },
  methods: {
    async loadPage(page = 1) {
      this.loading = true;
      if (!page) page = 1;
      try {
        const response = await ApiService.post(
          this.url + (page - 1),
          this.filter
        );
        const data = response.data;
        this.totalPages = data.totalPages;
        this.items = data.content;
        this.totalItems = data.totalElements;
        this.page = data.number + 1;
      } catch (error) {
        console.error("Failed to load users:", error);
      } finally {
        this.loading = false;
      }
    },
    onPageChange(newPage) {
      this.loadPage(newPage);
    },
  },
  watch: {
    filter: {
      handler: function () {
        this.debouncedLoadPage(1);
        this.$emit("filter-changed", this.filter);
      },
      deep: true,
    },
  },
  created() {
    this.debouncedLoadPage = debounce((page) => this.loadPage(page), 300);
    this.loadPage();
  },
};
</script>

<template>
  <slot name="filter" :filter="filter" :totalItems="totalItems">
    <v-text-field
      append-inner-icon="mdi-magnify"
      v-model="query"
      :label="$t('form.search') + '...'"
    />
  </slot>
  <slot :items="items" :totalItems="totalItems" :loading="loading">
    <v-row>
      <template v-if="loading">
        <v-col v-for="n in pageSize" :key="n" cols="12" md="6">
          <v-sheet class="pa-3">
            <v-skeleton-loader type="article" height="200"></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </template>
      <template v-else>
        <p v-if="items.length <= 0">Keine Objekte gefunden</p>
        <v-col v-for="item of items" :key="item.id" cols="12" md="6">
          <slot :item="item" name="item" />
        </v-col>
      </template>
    </v-row>
  </slot>
  <v-pagination
    v-model="page"
    :length="totalPages"
    @update:modelValue="onPageChange"
  ></v-pagination>
</template>

<style scoped></style>
