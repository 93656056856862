<script>
import ServerStateIcon from "@/components/navigation/ServerStateIcon.vue";
import ToggleSettingsButton from "@/components/navigation/ToggleSettingsButton.vue";
import LanguageSwitch from "@/components/LanguageSwitch.vue";
import GreetingUser from "@/components/dashboard/GreetingUser.vue";
import NotificationBell from "@/components/notification/NotificationBell.vue";
import CurrentUserAvatar from "@/components/CurrentUserAvatar.vue";
import {useAuthUserStore} from "@/stores/modules/auth.module";
import StartTour from "@/components/dashboard/StartTour.vue";

export default {
  name: "GkHeader",
  components: {
    StartTour,
    CurrentUserAvatar, NotificationBell, GreetingUser, LanguageSwitch, ToggleSettingsButton, ServerStateIcon },
  setup(){
    const authStore = useAuthUserStore();
    return {
      authStore
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.display.mobile;
    },
  },
  methods: {
    home() {
      this.$router.push("/");
    },
    redirectToProfile() {
      if (!this.authStore.hasRole("ROLE_GESCHAEFTSKUNDE")) {
        this.$router.push("/profil");
      }
    },
  },
};
</script>

<template>
  <v-app-bar class="app-bar bg-primary" elevation="1" app>
    <v-container class="d-flex justify-space-between flex-nowrap">
      <div id="prepend" class="d-flex justify-start flex-nowrap align-center">
        <v-img
            style="cursor: pointer"
            @click="home"
            :src="require('@/assets/app_icon.svg')"
            cover
            width="30"
            height="30"
        ></v-img>
        <div class="py-2" v-if="$route.meta && $route.meta.appBarBackButton">
          <v-btn icon="mdi-chevron-left" @click="$router.back()"></v-btn>
        </div>
        <GreetingUser v-if="!mobile" small/>
      </div>
      <div class="d-flex justify-end flex-nowrap align-center">
        <ServerStateIcon />
        <NotificationBell v-if="!mobile" ref="notifications" />
        <StartTour v-if="!mobile"/>
        <v-dialog class="text-wrap" max-width="500">
          <template #activator="{ props }">
            <v-btn icon v-bind="props"><v-icon>mdi-download</v-icon></v-btn>
          </template>
          <v-card class="pa-5">
            <v-card-title v-text="$t('topBar.saveToHomescreen')"></v-card-title>
            <v-card-subtitle></v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text>
              <ul>
                <li>
                  {{ $t("topBar.apple") }}
                </li>
                <li>
                  {{ $t("topBar.android") }}
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-dialog>
        <LanguageSwitch/>
        <ToggleSettingsButton />
      </div>
    </v-container>
  </v-app-bar>
</template>

