<template>
  <div class="text-left">
    <OwnerInformation :owner="object.objectOwner" />
    <p class="my-2">
      {{ $t("settingsPopup.admin.objects.verification.sellType") }}
      {{ object.sellType }}
    </p>
    <p class="my-2">
      {{ $t("settingsPopup.admin.objects.verification.objectType") }}
      {{ object.objectType.replace("TYPE_", "") }}
    </p>
    <p class="my-2">
      {{ $t("settingsPopup.admin.objects.verification.livingArea") }}
      {{ object.livingArea }}m²
    </p>
    <p class="my-2">
      {{ $t("settingsPopup.admin.objects.verification.baseArea") }}
      {{ object.landArea }}m²
    </p>
    <div class="pt-2" style="width: 100%; height: 200px">
      <MapDisplayOnly
        v-if="object.address.lat && object.address.lng"
        :marker-location="[object.address.lat, object.address.lng]"
      />
    </div>
    <div>
      <ImageGallery :images="object.images" />
    </div>
    <UserDisplaySmall :user-id="object.createdBy" />
  </div>
</template>

<script>
import OwnerInformation from "@/components/admin/OwnerInformation.vue";
import MapDisplayOnly from "@/components/map/MapDisplayOnly.vue";
import ImageGallery from "@/components/ImageGallery.vue";
import UserDisplaySmall from "@/components/admin/object/UserDisplaySmall.vue";
export default {
  name: "ObjectInfo",
  components: {
    UserDisplaySmall,
    ImageGallery,
    MapDisplayOnly,
    OwnerInformation,
  },
  props: {
    object: Object,
  },
};
</script>

<style scoped></style>
