// Pinia Store
import { defineStore } from "pinia";

const state = () => ({
  showBottomSettings: false,
  showIdentification: false,
  showTour: false,
});
export const useAppStore = defineStore("appStore", {
  state: state,

  // getters
  getters: {},

  // actions
  actions: {
    toggleBottomSettings() {
      this.showBottomSettings = !this.showBottomSettings;
    },
    toggleIdentificationDialog(value) {
      if (value) this.showIdentification = value;
      else this.showIdentification = !this.showIdentification;
    },
    addCoins() {},
    addTokens() {},
    toggleTour(value) {
      console.log("toggleTour", value);
      if (value !== undefined) {
        this.showTour = value;
      } else {
        this.showTour = !this.showTour;
      }
    },
  },
});
