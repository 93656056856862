<template>
  <v-btn icon @click="toggleSettingsmenu">
    <CurrentUserAvatar
        :size="25"
        style="border: 2px solid white"
    ></CurrentUserAvatar>
  </v-btn>
</template>
<script>
import {useAppStore} from "@/stores/app.module";
import CurrentUserAvatar from "@/components/CurrentUserAvatar.vue";

export default {
  name: 'ToggleSettingsButton',
  components: {CurrentUserAvatar},
  setup: () => {
    const appStore = useAppStore();
    return {
      appStore
    }
  },
  methods:{
    toggleSettingsmenu(){
      this.appStore.toggleBottomSettings();
    }
  }
}
</script>