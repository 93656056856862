<template>
  <v-app-bar
    v-if="$route.name != 'Seite'"
    class="app-bar bg-primary"
    elevation="1"
    app
  >
    <v-container class="d-flex justify-space-between flex-nowrap">
      <div id="prepend" class="d-flex justify-start flex-nowrap align-center">

        <div class="py-2" v-if="$route.meta && $route.meta.appBarBackButton">
          <v-btn icon="mdi-chevron-left" @click="$router.back()"></v-btn>
        </div>
        <v-img
            v-if="authStore.hasRole('ROLE_GESCHAEFTSKUNDE')"
            style="cursor: pointer"
            @click="home"
            :src="require('@/assets/app_icon.svg')"
            cover
            width="30"
            height="30"
        ></v-img>
        <div class="pl-1">
          <div
              style="width: 150px"
              v-if="!authStore.hasRole('ROLE_GESCHAEFTSKUNDE')"
          >
            <ProgressBarSmall />
          </div>
        </div>
        <GreetingUser small v-if="!mobile"/>
      </div>
      <div id="append"  class="d-flex justify-end flex-nowrap align-center">
        <ServerStateIcon />
        <NotificationBell v-if="!mobile" ref="notifications" />

        <v-dialog class="flex flex-wrap" max-width="500">
          <template #activator="{ props }">
            <v-btn icon v-bind="props"><v-icon>mdi-download</v-icon></v-btn>
          </template>
          <v-card class="pa-5">
            <v-card-title
                class=""
                v-text="$t('topBar.saveToHomescreen')"
            ></v-card-title>
            <v-card-subtitle></v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text>
              <ul>
                <li>
                  {{ $t("topBar.apple") }}
                </li>
                <li>
                  {{ $t("topBar.android") }}
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-btn
            v-if="!authStore.hasRole('ROLE_GESCHAEFTSKUNDE')"
            icon
            @click="$router.push('/fortschritt')"
        ><v-badge
            v-if="unclaimedRewards.length > 0"
            :content="unclaimedRewards.length"
            color="accent"
        ><v-icon icon="mdi-gift"></v-icon></v-badge
        ><v-icon v-else icon="mdi-gift"></v-icon
        ></v-btn>
        <LanguageSwitch/>
        <ToggleSettingsButton />
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
import ProgressBarSmall from "@/components/progress/ProgressBarSmall";
import { useAppStore } from "@/stores/app.module";
import { useAuthUserStore } from "@/stores/modules/auth.module";
import { useRewardStore } from "@/stores/reward.module";
import { useStatusStore } from "@/stores/status.module";
import ServerStateIcon from "@/components/navigation/ServerStateIcon.vue";
import ToggleSettingsButton from "@/components/navigation/ToggleSettingsButton.vue";
import LanguageSwitch from "@/components/LanguageSwitch.vue";
import GreetingUser from "@/components/dashboard/GreetingUser.vue";
import NotificationBell from "@/components/notification/NotificationBell.vue";

export default {
  name: "TopNavigationNew",
  components: {
    NotificationBell, GreetingUser, LanguageSwitch, ToggleSettingsButton, ServerStateIcon, ProgressBarSmall },
  setup() {
    const statusStore = useStatusStore();
    const appStore = useAppStore();
    const authStore = useAuthUserStore();
    const rewardStore = useRewardStore();
    rewardStore.getMyRewards();

    return {
      statusStore,
      appStore,
      authStore,
      rewardStore,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.display.mobile;
    },
    unclaimedRewards() {
      return this.rewardStore.rewards.filter((x) => !x.claimed);
    },

    currentUser() {
      return this.authStore.user;
    },
    greeting() {
      let moment = require("moment");

      let time = moment().format("HH");
      if (time >= 4 && time < 12) return "Guten Morgen, ";
      else if (time >= 12 && time < 13) return "Mahlzeit, ";
      else if (time >= 18 && time < 23) return "Guten Abend, ";
      else return "Guten Tag, ";
    },
  },
  methods: {
    redirectToProfile() {
      if (!this.authStore.hasRole("ROLE_GESCHAEFTSKUNDE")) {
        this.$router.push("/profil");
      }
    },
    home() {
      this.$router.push("/");
    },
    toggleSettingsmenu() {
      this.appStore.toggleBottomSettings();
    },
  },
};
</script>
