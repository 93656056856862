import ApiService from "@/services/api.service";

export async function getAddressByObject(id) {

    return await ApiService.getBetter(`object/${id}/address/get`);

}

export async function saveAddress(address) {

    return await ApiService.post(`object/address/update`, address);

}