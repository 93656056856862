<script>
import MapDisplay from "@/components/map/MapDisplay.vue";
import Address from "@/models/address";
import {useObjectStore} from "@/stores/objects.module";
import {useUserStore} from "@/stores/user.module";
import ApiService from "@/services/api.service";
import {getAddressByObject, saveAddress} from "@/services/address.service";
import {useNotificationStore} from "@/stores/notification.module";

export default {
name: "EditAddress" ,
  components: {MapDisplay},
  props:{
    objectId:{
      type:Number,
      required:true
    },
  },

  setup(){
    const objectStore = useObjectStore();
    const userStore = useUserStore();
    const notificationsStore = useNotificationStore();
    return{
      notificationsStore,
      objectStore,
      userStore
    }

  }
  ,
  data(){
    return{
      loading:false,
      required:
          value => {
            if (value) return true
            return 'Bitte fülle dieses Feld aus.'
          },
      callingOpenStreetMapApi:false,
      address:new Address(),
      autoFill:false,
      addressHint:"Mit ändern dieses Feldes werden die Karte sowohl die anderen Address Felder automatisch ausgefüllt. Wird die Adresse nicht richtig erkannt, kann ebenfalls noch die Stadt eingegeben werden."
    }
  },
  created() {
    this.getAddress();
  },
  methods:{
  async getAddress() {
    let response = await getAddressByObject(this.objectId).catch(e => {
      this.address=new Address();
    });
    this.address = response.data;
    this.newCenter();
  },
    updateMarker(latLng){
      this.addressObject.lat=latLng.lat;
      this.addressObject.lng=latLng.lng;
    },
    async save(){
    this.loading=true;
      const { valid } = await this.$refs.addressForm.validate()

      if (valid) {
        const response=await saveAddress(this.addressObject).catch(e => {
          console.log(e);
        }).finally(()=>{
          this.loading=false;
        });
        if(response.status===200) {
          this.notificationsStore.pushSimple("Adresse gespeichert.","SUCCESS");
        }else{
          this.notificationsStore.pushSimple("Adresse konnte nicht gespeichert werden.","ERROR");
        }
        this.$emit('updateAddress',this.addressObject);
      }
    },
    newCenter(){
      this.mapMarker=[this.addressObject.lat, this.addressObject.lon];
    },
    centerMapOnAddress(query){
      let self=this;
      ApiService.callOpenStreetMapApi(query).then(response => {

        if(response.status===200){
          if(!Array.isArray(response.data)){
            self.addressHint="Serverfehler."
            self.callingOpenStreetMapApi=false;
            return null;
          }
          if(response.data.length>0 && response.data[0].address){
            let coords=[response.data[0].lat,response.data[0].lon];
            this.addressObject.lat=response.data[0].lat;
            this.addressObject.lng=response.data[0].lon;
            let address= response.data[0].address;
            this.mapMarker=coords;
            //If Autofill not selected then break here;
            if(!this.autoFill) return null;
            //else Autofill fields
            this.addressObject.postcode=address.postcode;
            this.addressObject.city=address.city;
            this.addressObject.country=address.country;

            self.addressHint="Adresse eingefügt."
            self.callingOpenStreetMapApi=false;
          }else{
            self.addressHint="Adresse nicht gefunden."
          }

        }
      })
    },
    autocompleteEmptyFields(e){

      if(!this.autoFill) return null;
      this.addressHint="Suche Adresse..."
      console.log(e);
      this.callingOpenStreetMapApi=true;
      let query=this.query;
      if(query!=null) {
        this.centerMapOnAddress(query);
      }else{
        this.callingOpenStreetMapApi=false;
      }
    }
  },
  computed:{
    mapMarker(){
      if(this.addressObject.lat && this.addressObject.lng) return [this.addressObject.lat,this.addressObject.lng];
      else return [0,0]
    },
    showDialog(){
      return this.show;
    },
    addressObject:{
      get(){
        return this.address;
      },set(value){
        this.$emit("updateAddress",value);
      }
    },
    query(){
      let street=this.addressObject.street;
      let city=this.addressObject.city;
      if(street && city) {
        return street + ' ' + city;
      }else if(street){
        return street;
      }else if(city){
        return city;
      }else{
        return null;
      }
    }
  }
}
</script>

<template>
  <div style="width:100%;height:200px">
    <MapDisplay v-if="mapMarker" @mapUpdate="updateMarker" :selection-from-parent="mapMarker"/>
  </div>
  <v-container class="text-white">
    <v-form ref="addressForm" @submit.prevent="save">
      <v-text-field :rules="[required]" :hint="addressHint" v-model="addressObject.street" @change="autocompleteEmptyFields" :label="$t('address.street')+' & '+$t('address.houseNumber')">
        <template #append-inner>
          <v-tooltip>
            <template #activator="{props}">

              <v-icon @click="centerMapOnAddress(query)" style="cursor:pointer;" v-bind="props" icon="mdi-crosshairs-gps"></v-icon>

            </template>
            {{$t('address.showOnMap')}}
          </v-tooltip>

        </template>
      </v-text-field>
      <v-row>
        <v-col cols="3">
          <v-text-field :rules="[required]"  :loading="callingOpenStreetMapApi" v-model="addressObject.postcode" :label="$t('address.zip')"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field :rules="[required]"  @change="autocompleteEmptyFields" :loading="callingOpenStreetMapApi" v-model="addressObject.city" :label="$t('address.street')"></v-text-field>

        </v-col>
      </v-row>

      <v-text-field :rules="[required]"  :loading="callingOpenStreetMapApi" v-model="addressObject.federal" :label="$t('address.bundesland')"></v-text-field>
      <v-text-field :rules="[required]"  :loading="callingOpenStreetMapApi" v-model="addressObject.country" :label="$t('address.country')"></v-text-field>
      <v-btn :loading="loading" type="submit" block color="success">{{$t('buttons.save')}}</v-btn>
    </v-form>
  </v-container>
</template>

<style scoped>

</style>