<script>
import ReadableNumber from "@/components/helpers/ReadableNumber.vue";
import EnumDisplayLanguage from "@/components/helpers/EnumDisplayLanguage.vue";

export default {
  name: "GkTextSheet" ,
  components: {EnumDisplayLanguage, ReadableNumber}
}
</script>

<template>
  <v-dialog min-height="500" max-width="500">
    <template #activator="{props}">
      <v-sheet
          v-bind="props"
          class="hover-sheet flex-column px-5 py-5 d-flex justify-center align-center text-left"
          rounded
          style="position: relative; overflow: hidden"
      >
        <p v-html="$t('refer')"></p>
        <v-icon style="font-size: 4rem" size="x-large">mdi-plus</v-icon>
      </v-sheet>
    </template>
    <v-card>
      <v-card-text class="d-flex flex-column align-center justify-center">
        <div v-html="$t('referHint2')"></div>
        <v-btn href="mailto:info@estateboost.de" class="mx-auto" color="accent" variant="text" >{{$t('refer')}}</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>

</template>

<style scoped>
.hover-sheet {
  cursor: pointer;
  box-sizing: border-box!important;
}
.hover-sheet:hover {
  outline: 1px solid #cca876;
}
</style>