<template>
  <v-app id="app">
    <TopNavigationNew
      v-if="authUserStore.status.loggedIn && !$route.meta.hideHeader"
    />
    <HotjarImplementation/>
    <v-main class="bg-primary" style="position: relative">
      <NotificationWrapper v-if="authUserStore.status.loggedIn" />
      <ConfirmationTokenBanner
        v-if="authUserStore.status.loggedIn && !isUserEnabled"
      />
      <router-view id="v-step-0" />
    </v-main>

    <AppNavigation
      v-if="
        !$route.meta.auth &&
        !$route.meta.hideFooter &&
        !authUserStore.hasRole('ROLE_GESCHAEFTSKUNDE')
      "
    />
    <v-footer
      v-if="!$route.meta.hideFooter"
      class="bg-white"
      app
      height="20"
      order="0"
    ></v-footer>
    <SettingsBottomPopup />
    <div v-if="authUserStore.status.loggedIn">
      <NewRewardPopup
        v-for="reward of progressUserStore.newRewards"
        :key="reward.id"
        :reward="reward"
      />
    </div>
    <!--<DailyRaffleDisplay v-if="userCanRaffle"/>-->
    <AddIdentificationDocumentDialogGlobal
      v-if="authUserStore.status.loggedIn"
    />
  </v-app>
</template>
<script>
import { defineComponent } from "vue";
import { useAuthUserStore } from "@/stores/modules/auth.module";
import AppNavigation from "@/components/navigation/AppNavigation";
import { useUserProgressStore } from "@/stores/progress.module";
import SettingsBottomPopup from "@/components/navigation/SettingsBottomPopup";
import NewRewardPopup from "@/components/progress/NewRewardPopup";
import { useRewardStore } from "@/stores/reward.module";
import AddIdentificationDocumentDialogGlobal from "@/components/identification/AddIdentificationDocumentDialogGlobal";
import { useUserStore } from "@/stores/user.module";
import TopNavigationNew from "@/components/navigation/TopNavigationNew";
import NotificationWrapper from "@/components/notification/NotificationWrapper";
import { useStatusStore } from "@/stores/status.module";
import ConfirmationTokenBanner from "@/components/auth/ConfirmationTokenBanner";
import HotjarImplementation from "@/components/HotjarImplementation.vue";

export default defineComponent({
  components: {
    HotjarImplementation,
    ConfirmationTokenBanner,
    NotificationWrapper,
    TopNavigationNew,
    AddIdentificationDocumentDialogGlobal,
    NewRewardPopup,
    SettingsBottomPopup,
    AppNavigation,
  },
  setup() {
    const statusStore = useStatusStore();
    statusStore.checkServerState();
    const authUserStore = useAuthUserStore();
    const progressUserStore = useUserProgressStore();
    const rewardStore = useRewardStore();
    const userStore = useUserStore();
    const language = navigator.language;

    if (authUserStore.status.loggedIn) {
      rewardStore.canUserRaffle();
      progressUserStore.getProgress();
      userStore.getMyFreeSlots();
    }

    //console.log(navigator.connection);

    return {
      // you can also access the whole store in your component by returning it
      authUserStore,
      progressUserStore,
      rewardStore,
      language,
    };
  },
  data() {
    return {
      steps: [],
    };
  },
  computed: {
    userCanRaffle() {
      return this.rewardStore.canRaffle;
    },
    isUserEnabled() {
      return this.authUserStore.user.emailVerified;
    },
  },
  methods: {
    onScroll(e) {
      console.log(e);
    },
    setLanguageInitial() {
      let browserLocale = this.language.split("-")[0];
      let userLocale = this.authUserStore.user?.languageCode;
      if (userLocale) {
        console.log("Setting user locale", userLocale);
        this.$i18n.locale = userLocale;
        return;
      }
      if (this.$i18n.availableLocales.find((x) => x === browserLocale)) {
        console.log("Setting browser locale", browserLocale);
        this.$i18n.locale = browserLocale;
        return;
      }
    },
  },
  mounted() {
    this.setLanguageInitial();
  },
});
</script>
<style>
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.customTooltip * {
  color: black;
  background-color: #ffffff;
  font-size: 18px;
  border-radius: 5px;
}

.customTooltip .introjs-tooltip-title {
  color: black;
}
.customButton {
  background-color: #ffffff;
  border-radius: 5px;
  font-size: 18px;
  padding: 5px 10px;
  margin: 5px;
  display: inline-flex;
  align-items: center;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.customButton:hover {
  background-color: #f0f0f0;
}
</style>
