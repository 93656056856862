<template>
  <v-container>
  <v-skeleton-loader v-if="loading" height="500px" width="100%" type="card" />
  <v-row v-else-if="estateObject && verification">
    <v-col>
      <v-list-item class="bg-secondary text-left">
        <template #prepend>
          <v-icon>
            mdi-exclamation
          </v-icon>
        </template>
        <template #subtitle>
          <EnumDisplayLanguage :enum-item="verification.state" enum-name="EVerificationState"></EnumDisplayLanguage>
          <br/>
          <p v-if="verification.failureMessage">{{$t('verification.failureReason')}}: <span v-text="verification.failureMessage"></span></p>
        </template>
        <template #title>
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <span v-bind="props" v-html="objectName"></span>
            </template>
            <span v-html="objectName"></span>
          </v-tooltip>
        </template>
        <template #append>
          <v-btn icon @click="verify" color="success" variant="text"
          ><v-icon icon="mdi-check"></v-icon
          ></v-btn>
          <v-dialog persistent v-model="declineDialog">
            <template #activator="{ props }">
              <v-btn v-bind="props" icon color="error" variant="text"
              ><v-icon icon="mdi-close"></v-icon
              ></v-btn>
            </template>
            <v-card>
              <v-card-text>
                <p>
                  {{
                    $t("settingsPopup.admin.objects.verification.whyError")
                  }}
                </p>
                <v-textarea
                    class="mt-2"
                    :placeholder="
                      $t(
                        'settingsPopup.admin.objects.verification.whyErrorPlaceholder'
                      )
                    "
                    v-model="declineMessage"
                    :label="
                      $t(
                        'settingsPopup.admin.objects.verification.whyErrorLabel'
                      )
                    "
                >
                </v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-btn variant="text" @click="declineDialog = false">{{
                    $t("settingsPopup.admin.objects.verification.cancel")
                  }}</v-btn
                ><v-spacer /><v-btn @click="decline" color="warning">{{
                  $t("settingsPopup.admin.objects.verification.send")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-list-item>
      <v-btn @click="download" class="my-5" text
      ><v-icon>mdi-download</v-icon>
        {{
          $t("settingsPopup.admin.objects.verification.declarationOfConsent")
        }}</v-btn
      >

    </v-col>
    <v-col>
      <ObjectInfo :object="estateObject" />
    </v-col>
  </v-row>
  <div v-else>
    <v-alert type="error">{{ $t("settingsPopup.admin.objects.verification.notFound") }}</v-alert>

  </div>
  </v-container>
</template>

<script>
import ObjectInfo from "@/components/admin/object/ObjectInfo.vue";
import {
  acceptVerification,
  declineVerification,
} from "@/services/admin.service";
import { useAdminStore } from "@/stores/admin.module";
import { downloadFileFromBase64 } from "@/services/file.service";
import {getVerificationNew} from "@/services/verification.service";
import EnumDisplayLanguage from "@/components/helpers/EnumDisplayLanguage.vue";

export default {
  name: "VerificationItem",
  components: {EnumDisplayLanguage, ObjectInfo },
  setup() {
    const adminStore = useAdminStore();
    return {
      adminStore,
    };
  },
  props: {
    objectId: Number,
  },
  data() {
    return {
      loading: false,
      estateObject: null,
      declineMessage: "",
      declineDialog: false,
      consent: null,
      verification: null,
    };
  },
 async created() {
  this.loading = true;
  try {
    let response = await getVerificationNew(this.objectId);
    this.verification = response?.verification;
    this.estateObject = response?.estateObject;
    this.consent = response?.consentFile;
  } catch (error) {
    console.error("Error fetching verification data:", error);
  } finally {
    this.loading = false;
  }
},
  methods: {
    download() {
      downloadFileFromBase64(this.consent);
    },
    async verify() {
      await acceptVerification(this.objectId);
      this.$router.push("/admin/verifications");
    },
    async decline() {
      await declineVerification(this.objectId, this.declineMessage);
      this.declineDialog = false;
      this.adminStore.getPendingVerifications();
    },
  },
  computed: {
  },
};
</script>

<style scoped></style>
