<template>
  <v-row no-gutters>
    <v-col cols="2">
      <UserAvatar tile :size="65" class="ml-4" :user="contact" />
    </v-col>
    <v-col cols="10" class="pl-6">
      <v-list-item
        class="text-left position-relative cursor-pointer"
        @mouseenter="showEditIcon = true"
        @mouseleave="showEditIcon = false"
      >
        <!-- Anzeige-Modus -->
        <template v-if="!isEditing">
          <v-list-item-title class="d-flex align-center">
            {{ contact.fullName }}
            <v-btn
              v-if="(showEditIcon || $vuetify.display.mdAndDown) && canEdit"
              size="small"
              :color="$vuetify.display.mdAndDown ? 'grey-darken-1' : 'grey'"
              variant="plain"
              icon="mdi-pencil"
              density="compact"
              class="ms-3 cursor-pointer"
              @click="startEditing"
            ></v-btn>
          </v-list-item-title>
          <v-list-item-subtitle>
            <p>
              <v-icon class="pa-0 mr-1" icon="mdi-mail"></v-icon>
              <a
                :href="'mailto:' + contact.email"
                class="text-white"
                style="cursor: pointer; font-size: 0.7rem"
                >{{ contact.email }}</a
              >
            </p>
            <p>
              <v-icon class="pa-0 mr-1" icon="mdi-phone"></v-icon>
              <a
                :href="'tel:' + contact.mobileNumber"
                class="text-white"
                style="cursor: pointer; font-size: 0.7rem"
                >{{ contact.mobileNumber }}</a
              >
            </p>
          </v-list-item-subtitle>
        </template>

        <!-- Bearbeitungs-Modus -->
        <template v-else>
          <v-autocomplete
            v-model="selectedContact"
            :items="users"
            :loading="loading"
            item-title="fullName"
            item-value="id"
            :label="$t('object.selectContact')"
            density="compact"
            variant="solo-filled"
            hide-details
            return-object
            @update:search="searchUsers"
          >
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props">
                <template v-slot:prepend>
                  <UserAvatar :size="32" :user="item.raw" />
                </template>
                <v-list-item-subtitle>{{
                  item.raw.email
                }}</v-list-item-subtitle>
              </v-list-item>
            </template>
            <template v-slot:append>
              <v-btn
                variant="plain"
                size="small"
                icon="mdi-check"
                density="comfortable"
                @click="saveEdit"
              ></v-btn>
              <v-btn
                variant="plain"
                size="small"
                icon="mdi-close"
                density="comfortable"
                @click="cancelEdit"
              ></v-btn>
            </template>
          </v-autocomplete>
        </template>
      </v-list-item>
    </v-col>
  </v-row>
</template>

<script>
import UserAvatar from "@/components/user/UserAvatar.vue";
import ApiService from "@/services/api.service";
import { useNotificationStore } from "@/stores/notification.module";
import NotificationElephant from "@/models/notification";

export default {
  name: "EditableContactCard",
  components: {
    UserAvatar,
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    objectId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const notificationStore = useNotificationStore();
    return { notificationStore };
  },
  data() {
    return {
      showEditIcon: false,
      isEditing: false,
      selectedContact: null,
      users: [],
      loading: false,
    };
  },
  methods: {
    startEditing() {
      if (!this.canEdit) return;
      this.selectedContact = this.contact;
      this.isEditing = true;
      this.searchUsers();
    },
    async searchUsers(search) {
      try {
        this.loading = true;
        const response = await ApiService.get("user/list/role/ROLE_ADMIN");
        if (response?.data) {
          this.users = response.data;
        }
      } catch (error) {
        this.notificationStore.push(
          new NotificationElephant({
            title: this.$t("common.error"),
            message: error.message,
            color: "ERROR",
          })
        );
      } finally {
        this.loading = false;
      }
    },
    async saveEdit() {
      if (
        !this.selectedContact ||
        this.selectedContact.id === this.contact.id
      ) {
        this.cancelEdit();
        return;
      }
      try {
        const response = await ApiService.put(
          `object/${this.objectId}/update/createdBy`,
          { content: this.selectedContact.id }
        );

        if (response?.data) {
          this.$emit("update:contact", this.selectedContact);
          this.notificationStore.push(
            new NotificationElephant({
              title: this.$t("common.messages.updateSuccess"),
              color: "SUCCESS",
            })
          );
        }
      } catch (error) {
        this.notificationStore.push(
          new NotificationElephant({
            title: this.$t("common.messages.updateError"),
            message: error.message,
            color: "ERROR",
          })
        );
      } finally {
        this.isEditing = false;
      }
    },
    cancelEdit() {
      this.selectedContact = this.contact;
      this.isEditing = false;
    },
  },
};
</script>
