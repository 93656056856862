<template>
  <div @click="$router.push('/ranking')" class="sheetBehindParent" style="position:relative">
<v-sheet style="position: relative;z-index:2;border-radius: 20px"  color="blue-darken-1" class="pa-5">
<div class="d-flex justify-space-between align-center">
  <v-list-item class="pa-0 ma-0" align="left" width="70%" title="Du" :subtitle="currentRank?.points+' '+$t('points',currentRank?.points)">

  </v-list-item>
  <span v-if="currentRank" >{{$t('rank')}} <span v-html="currentRank.rank"></span></span>
</div>
  <v-sheet rounded style="overflow: hidden;border-radius: 20px" class="mt-1 d-flex justify-space-between align-center" color="secondary" height="50px">
    <trend
        style="height:100%;width:55%"
        v-if="rankingHistory"
        :data="rankingHistoryReactive"
        :gradient="['#6fa8dc', '#42b983', '#2c3e50']"
        :padding="4.8"
        :radius="12"
        :stroke-width="5"
        auto-draw
        smooth>
      >
    </trend>
    <v-chip :color="rankDifferenceColor"
            class="mr-5"
            v-if="currentRank"
            label
            text-color="white">
      <span>
         <v-icon size="small" v-if="currentRank.difference>0" color="success" icon="mdi-chevron-up"></v-icon>
    <v-icon size="x-small" v-else-if="currentRank.difference===0" icon="mdi-minus"></v-icon>
    <v-icon size="small" v-else-if="currentRank.difference<0"  color="red" icon="mdi-chevron-down"></v-icon>
      </span>
      <span v-html="Math.abs(currentRank.difference)">

    </span>

    </v-chip>
  </v-sheet>
</v-sheet>
    <v-sheet color="accent" class="sheetBehind tilted-right mx-auto " style="border-radius: 20px" height="100px"></v-sheet>
    <v-sheet color="accent" class="sheetBehind tilted-left mx-auto " style="border-radius: 20px" height="100px"></v-sheet>
  </div>
</template>

<script>
import {getRankingHistory, getUserRank} from "@/services/ranking.service";
import {useAuthUserStore} from "@/stores/modules/auth.module";

export default {
  name: "DashboardRanking",
  setup(){
    const authStore = useAuthUserStore();
    return{authStore}
  },
  data(){
    return{
      rankingHistory:null,
      currentRank:null
    }
  },
  computed:{
    rankDifferenceColor(){
      if(!this.currentRank) return 'grey';
      let difference = this.currentRank.difference;
      if(difference<0) return 'error';
      else if(difference>0) return 'success';
      else return 'grey';
    },
  currentUser(){
    return this.authStore.user;
  },
    rankingHistoryReactive(){
      if(!this.rankingHistory || this.rankingHistory.length<=0) return [0]
      else return this.rankingHistory;
    }
  },
  created() {
    this.getRankingHistory();
    this.getUserRanking();
  },
  methods:{
    async getRankingHistory(){
      let data = await getRankingHistory();
      this.rankingHistory=data.map(num => -num);
    },
    async getUserRanking(){
      this.currentRank= await getUserRank();
    }
  }
}
</script>

<style scoped>
.sheetBehind{
  position:absolute;
  top:-10px;
  width: 90%;
  z-index:0;
  left:50%;
  transition: all 0.5s ease-in-out;

}
.sheetBehindParent:hover .tilted-right{
  transform: translateX(-50%) rotateZ(2deg);
}
.sheetBehindParent:hover .tilted-left{
  transform: translateX(-50%) rotateZ(-2deg);
}
.tilted-right{
  transform: translateX(-50%) rotateZ(-2deg);
}
.tilted-left{
  transform: translateX(-50%) rotateZ(2deg);
  opacity: 0.6;
}
</style>