<template>
  <div style="margin-top:-20px;" class=" mb-2 text-left">
    <small v-if="!courseOneCompleted" v-text="$t('user.profile.checkEducation')"></small>
  </div>
  <div v-if="loading" style="gap:10px;" class="d-flex justify-start flex-wrap">
    <div style="width: calc(33.33% - 7px)" v-for="n in 9" :key="n">
      <v-sheet class="rounded-xl px-5 py-5 text-left" rounded>
        <v-skeleton-loader
            type="list-item-two-line"
        ></v-skeleton-loader>
        <div class="d-flex">
          <v-skeleton-loader
              type="chip"
              width="120"
              class="me-2"
          ></v-skeleton-loader>
          <v-skeleton-loader
              type="chip"
              width="130"
          ></v-skeleton-loader>
        </div>
      </v-sheet>
    </div>
  </div>
  <div v-else-if="type==='rows'" style="gap:10px;" class="d-flex justify-start flex-wrap">
    <ObjectDisplaySmall style="flex-grow: 1" v-for="object of myObjects" :key="object.id" :object="object"/>
    <FreeSlotCard v-for="slide of freeSlots" :key="slide+'free'" :course-one-completed="courseOneCompleted" :image="image(slide)" :slide="slide"/>
    <LockedSlot @unlock="buySlot"/>
  </div>
  <swiper
      v-else
      :slidesPerView="'auto'"
      :spaceBetween="10"
      :freeMode="true"
      :modules="modules"
      class="py-4 pl-1 pr-5"
  >
    <swiper-slide style="width: 300px" v-for="object of myObjects" :key="object.id">
    <ObjectDisplaySmall :object="object"/></swiper-slide>
    <swiper-slide style="width:250px" v-for="slide of freeSlots" :key="slide+'free'">
      <FreeSlotCard :course-one-completed="courseOneCompleted" :image="image(slide)" :slide="slide"/>
    </swiper-slide>
    <swiper-slide style="width:300px" :key="'locked'">
      <LockedSlot @unlock="buySlot"/>
    </swiper-slide>

  </swiper>

  <v-dialog v-model="buyShow" max-width="500">
    <v-card>
      <v-card-title>Slot kaufen</v-card-title>
      <v-card-text>
       <p class="" v-if="!insufficientSells"> Kaufe einen neuen Slot, um weitere Immobilien hinzuzufügen.
       </p>
        <v-alert class="mb-2" v-if="insufficientSells">{{$t('object.slots.buy.insufficientSales')}}</v-alert>
        <p><i>{{$t('object.slots.buy.commercialActivity')}}</i></p>
        <p>{{$t('object.slots.buy.priceCalculation')}}</p>
        <p>{{$t('object.price')}}: <span v-if="!insufficientSells" class="text-accent">{{slotPrice}} {{$t('wallet.token',2)}}</span><span v-else >-</span></p>
      </v-card-text>
      <v-card-actions>

        <v-btn @click="buyShow=false" variant="plain">{{$t('buttons.cancel')}}</v-btn>
      <v-btn :disabled="insufficientSells" color="accent" @click="buySlotNow">{{$t('buttons.buy')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--<v-slide-group v-model="active" center-active>
    <v-slide-group-item v-for="object of myObjects" :value="object.id" :key="object.id">
      <ObjectDisplaySmall :object="object" class="mx-2"/>
    </v-slide-group-item>
    <v-slide-group-item  v-for="slide of 2" :value="slide+'free'" :key="slide+'free'">
      <v-card color="blue" style="border-radius: 20px" class="pa-5 mr-2 d-flex flex-wrap" width="80vw" height="150px" elevation="2">
        <div style="width: 100%" class="text-white text-left">freier Slot</div>
        <div class="d-flex align-end justify-start">
          <v-btn variant="tonal" @click="$router.push('/immobilie/erstellen')" color="white" block size="small">Immobilie anlegen</v-btn>

        </div>
      </v-card>
    </v-slide-group-item>
    <v-slide-group-item v-for="slide in 1" :value="slide+'locked'" :key="slide+'locked'">
      <v-card class="pa-5  mr-2  d-flex flex-wrap justify-space-between" color="blue" width="80vw" height="150px" elevation="2">
        <div class="text-white text-left">gesperrter Slot <v-icon size="x-small" icon="mdi-lock"></v-icon></div>
        <div class="d-flex align-end">
          <v-btn variant="tonal" color="white" block size="small">Slot freischalten</v-btn>

        </div>
      </v-card>
    </v-slide-group-item>

  </v-slide-group>-->
</template>

<script>
import {defineComponent} from 'vue'
import ObjectDisplaySmall from "@/components/objects/ObjectDisplaySmall";
import {useObjectStore} from "@/stores/objects.module";
import {Swiper, SwiperSlide} from 'swiper/vue';
import {FreeMode} from 'swiper';

// Import Swiper styles
import 'swiper/css';
import {useUserStore} from "@/stores/user.module";
import userService from "@/services/user.service";
import FreeSlotCard from "@/components/objects/FreeSlotCard.vue";
import LockedSlot from "@/components/objects/LockedSlot.vue";

export default defineComponent({
  name: 'ObjectCaroussel',
  components: {
    LockedSlot,
    FreeSlotCard,
    ObjectDisplaySmall,
    Swiper,
    SwiperSlide,
  },
  props:{
    type:{
      type:String,
      default:'caroussel'
    }
  },
  setup(){
    const objectStore = useObjectStore();
    const userStore = useUserStore();
    objectStore.getMyObjects();
    return{
      objectStore,
      userStore
    }
  },
  watch:{
    myObjects(){
      this.userStore.getMyFreeSlots();
    }
  },
  computed:{
    loading(){
      return this.objectStore.loading;
    },
    freeSlots(){
      return this.userStore.currentUser.freeSlots;
    },
    myObjects(){
      return this.objectStore.myObjects;
    }
  },
  created() {
    this.getSlotPrice();
    this.hasCourseOneCompleted();
  },
  methods: {
    async hasCourseOneCompleted() {
      let self=this;
      this.courseOneCompleted = await userService.hasCourseOneCompleted().catch(()=>{
        self.courseOneCompleted=false;
      });

    },
    async getSlotPrice() {
      let self=this;
      this.slotPrice = await userService.calculateSlotPrice().catch(()=>{
        self.slotPrice=16000;
        self.insufficientSells=true;
      });
    },
    image(index){
      index--;
      if(index>=this.images.length){
        if(index%2===0) return this.images[0];
        else return this.images[1];
      }
      return this.images[index];
    },
    buySlot(){
      this.buyShow = true;
    },
    buySlotNow(){
      if (confirm(this.$t('object.slots.buy.confirm',{token:this.slotPrice})) == true) {
        this.userStore.buySlotWithToken();
      } else {
        return null;
      }
    },
    onSwiper: function () {
      console.log("onSwiper called");
    },
    onSlideChange: function () {
      console.log("Slide change");
    },
  },
  data(){
    return{
      courseOneCompleted:false,
      insufficientSells:false,
      slotPrice:80000,
      buyShow:false,
      active:null,
      modules:[FreeMode],
      images:[
          "alexander-andrews-A3DPhhAL6Zg-unsplash.jpg",
          "brandon-griggs-wR11KBaB86U-unsplash.jpg",
          "etienne-beauregard-riverin-B0aCvAVSX8E-unsplash.jpg",
      ]
    }
  }
})
</script>
