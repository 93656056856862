import ApiService from "@/services/api.service";


export async function requestVerification(objectId){
    const response = await ApiService.get("object/verification/request/"+objectId);
    return response.data;
}
export async function getVerification(objectId){
    const response = await ApiService.get("object/verification/get/"+objectId);
    return response.data;
}

export async  function getVerifications(){
    const response = await ApiService.get("object/verification/admin/all/pending/new");
    return response.data;
}

export async function getVerificationNew(objectId){
    const response = await ApiService.get("object/verification/get/"+objectId+"/new");
    return response.data;
}