<script>
import GreetingUser from "@/components/dashboard/GreetingUser.vue";
import ObjectCaroussel from "@/components/objects/ObjectCaroussel.vue";
import GKAllObjects from "@/views/geschaeftskunde/GKAllObjects.vue";

export default {
  name: "gkDashboardView",
  components: { GKAllObjects, ObjectCaroussel, GreetingUser},
  computed: {
    mobile() {
      return this.$vuetify.display.mobile;
    },
  },
}
</script>

<template>
<div>
  <GreetingUser v-if="mobile" gk ref="dashboard-greeting"/>
  <v-container>
    <div class="d-flex justify-start align-center">
      <h3 class="text-left mb-5 mt-10 heading-desktop">{{$t('yourObjects')}}</h3>
    </div>
    <GKAllObjects/>
  </v-container>


</div>
</template>

<style scoped>

</style>