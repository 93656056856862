<template>
  <div class="locale-changer">
    <v-menu offset-y v-model="open">
      <template v-slot:activator="{ props }">
        <v-chip color="white" variant="text" size="large" :append-icon="open ? 'mdi-chevron-up' : 'mdi-chevron-down'" v-bind="props">
          <template #default>
            <v-icon>mdi-web</v-icon>
          </template>
        </v-chip>
      </template>
      <v-list class="mt-2" style="border-radius: 10px">
        <v-list-item
          v-for="locale in locales"
          :key="locale"
          :active="locale === $i18n.locale"
          @click="changeLocale(locale)"
        >
          <template #prepend>
            <div class="fi mr-2" :class="'fi-'+locale"></div>
          </template>
          <v-list-item-title>{{ localeDisplayNames[locale] }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import {useAuthUserStore} from "@/stores/modules/auth.module";

export default {
  name: 'LanguageSwitch',
  setup(){
    const authStore = useAuthUserStore();
    return {authStore};
  },
  props:{
    noUser: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      locales: this.$i18n.availableLocales,
      open: false,
      localeDisplayNames: {
        gb: 'English',
        de: 'Deutsch',
        fr: 'Français',
        it: 'Italiano',
        tr: 'Türkçe',
        // Add other locales and their display names here
      }
    };
  },
  methods: {
    changeLocale(newLocale) {
      console.log('Changing locale to', newLocale);
      try{
        this.$i18n.locale = newLocale;
        if(!this.noUser) this.authStore.switchLanguage(newLocale);
      }catch (e){
        this.$i18n.locale = newLocale;

        console.error('Error changing locale', e);
      }
    }
  }
}
</script>