<template>
  <v-container >
    <GreetingUser v-if="mobile" ref="dashboard-greeting"/>
    <v-row class="pt-5">
      <v-col cols="6">
        <div class="d-flex align-center" ref="dashboard-performance">
          <YourPerformance/>
        </div>
      </v-col>
      <v-col cols="6">
        <div style="width:100%;height:100%;position: relative">
          <DashboardMessage desktop/>
        </div>
        </v-col>
    </v-row>
    <v-row>
      <v-col>

        <div data-v-step="2" ref="dashboard-objects">
          <h3 class="text-left mb-5 mt-10 heading-desktop">{{$t('dashboard.your_properties')}}</h3>
          <ObjectCaroussel type="rows"/>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="" align-self="end" cols="6">
        <div class="align-center">
          <h3 class="text-left mb-5 heading-desktop">{{$t('dashboard.ranking')}}</h3>
          <DashboardRanking/>
        </div>
      </v-col>
      <v-col align-self="end" cols="6">
        <h3 class="text-left mb-5 heading-desktop">{{$t('dashboard.education')}}</h3>
        <LatestCourse/>
      </v-col>
    </v-row>








  <v-row class="my-15">
    <v-col>
    <div style="height:100%;width:100%" class="d-flex justify-center">
      <DailyRewardDashboard/>

    </div>
    </v-col>  </v-row>
  </v-container>
</template>

<script>
import YourPerformance from "@/components/dashboard/YourPerformance";
import ObjectCaroussel from "@/components/objects/ObjectCaroussel";
import DashboardRanking from "@/components/dashboard/DashboardRanking";
import DailyRewardDashboard from "@/components/dashboard/DailyRewardDashboard";
import GreetingUser from "@/components/dashboard/GreetingUser";
import LatestCourse from "@/components/dashboard/LatestCourse";
import DashboardMessage from "@/components/dashboard/DashboardMessage";
export default {
  name: 'HomeView',
  data(){
    return{
    }
  },
  methods:{

    },
  computed:{
    mobile(){
      return this.$vuetify.display.mobile;
    }
  },
  components: {
    DashboardMessage,
    LatestCourse,
    GreetingUser,
    DailyRewardDashboard,
    DashboardRanking,
    ObjectCaroussel,
    YourPerformance
  }
}
</script>
<style>
.heading-desktop{
  opacity: 0.4;
  font-size: 2rem;
}
</style>
