<template>
  <v-container
    class="d-flex justify-space-between align-center"
    v-if="currentUser"
  >
    <div :style="{ lineHeight: small ? '10px' : 'normal' }" @click="redirectToProfile" class="text-left">   <small :class="{'text-disabled': true, 'text-small': small}">{{ greeting }}</small><br/>
      <span :class="{'text-h5': true, 'text-h7': small}">
  {{ currentUser.firstName }} {{ currentUser.lastName }}
</span>
    </div>

    <div v-if="!small" class="d-flex align-center">
      <StartTour :gk="gk" :start-tour="startTour"/>
      <NotificationBell ref="notifications" />
      <CurrentUserAvatar
        @click="redirectToProfile"
        :size="50"
        style="border: 2px solid white"
      ></CurrentUserAvatar>
    </div>
  </v-container>
</template>

<script>
import {useAppStore} from "@/stores/app.module";
import {useAuthUserStore} from "@/stores/modules/auth.module";
import {useRewardStore} from "@/stores/reward.module";
import CurrentUserAvatar from "@/components/CurrentUserAvatar";
import NotificationBell from "@/components/notification/NotificationBell";
import StartTour from "@/components/dashboard/StartTour.vue";

export default {
  name: "GreetingUser",
  props:{
    gk:{
      type:Boolean,
      default:false
    },
    small:{
      type:Boolean,
      default:false
    }
  },
  components: {StartTour, NotificationBell, CurrentUserAvatar },
  setup() {
    const appStore = useAppStore();
    const authStore = useAuthUserStore();
    const rewardStore = useRewardStore();
    rewardStore.getMyRewards();

    return {
      appStore,
      authStore,
      rewardStore,
    };
  },
  computed: {
    currentUser() {
      return this.authStore.user;
    },
    greeting() {
      let moment = require("moment");

      let time = moment().format("HH");
      if (time >= 4 && time < 12) return this.$t("greeting.morning") + ", ";
      else if (time >= 12 && time < 13) return this.$t("greeting.lunch") + ", ";
      else if (time >= 18 && time < 23)
        return this.$t("greeting.evening") + ", ";
      else return this.$t("greeting.default") + ", ";
    },
  },
  methods: {
    redirectToProfile() {
      if (!this.authStore.hasRole("ROLE_GESCHAEFTSKUNDE")) {
        this.$router.push("/profil");
      }
    },
    startTour() {
      this.appStore.$patch((state) => {
        state.showTour = true;
      });
    },
  },
};
</script>
<style scoped>
.text-small{
  font-size: 0.7rem;
}
.text-h7{
  font-size: 1.2rem!important;
}
</style>