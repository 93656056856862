<template>
  <v-dialog fullscreen v-model="showDialog">
    <v-card>
      <v-toolbar><v-btn icon @click="$router.back()"><v-icon icon="mdi-close"></v-icon></v-btn><v-toolbar-title>{{$t('address.title')}}</v-toolbar-title></v-toolbar>
    <div style="width:100%;height:200px">
      <MapDisplay @mapUpdate="updateMarker" :selection-from-parent="mapMarker"/>
    </div>
    <v-container class="text-white">
      <v-form ref="addressForm" @submit.prevent="save">
    <v-text-field :rules="[required]" :hint="addressHint" v-model="addressObject.street" @change="autocompleteEmptyFields" :label="$t('address.street')+' & '+$t('address.houseNumber')">
      <template #append-inner>
        <v-tooltip>
          <template #activator="{props}">

              <v-icon @click="centerMapOnAddress(query)" style="cursor:pointer;" v-bind="props" icon="mdi-crosshairs-gps"></v-icon>

          </template>
          {{$t('address.showOnMap')}}
        </v-tooltip>

      </template>
    </v-text-field>
    <v-row>
      <v-col cols="3">
        <v-text-field :rules="[required]"  :loading="callingOpenStreetMapApi" v-model="addressObject.postcode" :label="$t('address.zip')"></v-text-field>
      </v-col>
      <v-col>
        <v-text-field :rules="[required]"  @change="autocompleteEmptyFields" :loading="callingOpenStreetMapApi" v-model="addressObject.city" :label="$t('address.city')"></v-text-field>

      </v-col>
      </v-row>

        <v-text-field :rules="[required]"  :loading="callingOpenStreetMapApi" v-model="addressObject.federal" :label="$t('address.bundesland')"></v-text-field>
      <v-text-field :rules="[required]"  :loading="callingOpenStreetMapApi" v-model="addressObject.country" :label="$t('address.country')"></v-text-field>
      <v-btn type="submit" block color="success">{{$t('buttons.save')}}</v-btn>
      </v-form>
    </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from '@/services/api.service';
import MapDisplay from "@/components/map/MapDisplay";
import {useObjectStore} from "@/stores/objects.module";
import Address from "@/models/address";
import {useUserStore} from "@/stores/user.module";
export default {
  name: "EditAddressView",
  components: {MapDisplay},
  props:{
    address:{
      default:new Address(),
      type:Object
    },
    show:Boolean
  },

    setup(){
      const objectStore = useObjectStore();
      const userStore = useUserStore();
      return{
        objectStore,
        userStore
      }

    }
  ,
  data(){
    return{
      required:
        value => {
          if (value) return true
          return 'Bitte fülle dieses Feld aus.'
        },
      callingOpenStreetMapApi:false,

      autoFill:false,
      addressHint:"Mit ändern dieses Feldes werden die Karte sowohl die anderen Address Felder automatisch ausgefüllt. Wird die Adresse nicht richtig erkannt, kann ebenfalls noch die Stadt eingegeben werden."
    }
  },
  methods:{
    updateMarker(latLng){
      this.addressObject.lat=latLng.lat;
      //console.log("Neues Object",this.addressObject);
      this.addressObject.lng=latLng.lng;
    },
    async save(){
      const { valid } = await this.$refs.addressForm.validate()

      if (valid) {
        //TODO Catch in Parent
        this.$emit('updateAddress',this.addressObject);
        //TODO das gehört hier nicht hin
        //
        //alert("Saved")
        this.$emit("toggleDialog",false);
      }

    },
    centerMapOnAddress(query){
      let self=this;
      ApiService.callOpenStreetMapApi(query).then(response => {

        if(response.status===200){
          if(!Array.isArray(response.data)){
            self.addressHint="Serverfehler."
            self.callingOpenStreetMapApi=false;
            return null;
          }
          if(response.data.length>0 && response.data[0].address){
            let coords=[response.data[0].lat,response.data[0].lon];
            this.addressObject.lat=response.data[0].lat;
            this.addressObject.lng=response.data[0].lon;
            let address= response.data[0].address;
            this.mapMarker=coords;
            //If Autofill not selected then break here;
            if(!this.autoFill) return null;
            //else Autofill fields
            console.log(address);
            this.addressObject.postcode=address.postcode;
            this.addressObject.city=address.city;
            this.addressObject.country=address.country;

            self.addressHint="Adresse eingefügt."
            self.callingOpenStreetMapApi=false;
          }else{
            self.addressHint="Adresse nicht gefunden."
          }

        }
      })
    },
    autocompleteEmptyFields(e){

      if(!this.autoFill) return null;
      this.addressHint="Suche Adresse..."
      console.log(e);
      this.callingOpenStreetMapApi=true;
      let query=this.query;
      if(query!=null) {
        this.centerMapOnAddress(query);
      }else{
        this.callingOpenStreetMapApi=false;
      }
    }
  },
  computed:{
    mapMarker(){
      if(this.addressObject.lat && this.addressObject.lng) return [this.addressObject.lat,this.addressObject.lng];
      else return [0,0]
    },
    showDialog(){
      return this.show;
    },
    addressObject:{
      get(){
        return this.address;
      },set(value){
        this.$emit("updateAddress",value);
      }
    },
    query(){
      let street=this.addressObject.street;
      let city=this.addressObject.city;
      if(street && city) {
        return street + ' ' + city;
      }else if(street){
        return street;
      }else if(city){
        return city;
      }else{
        return null;
      }
    }
  }
}
</script>

<style scoped>

</style>