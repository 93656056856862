<template>
  <div class="d-flex flex-column">
    <h4>{{label}}</h4>
    <v-range-slider
        density="compact"
        thumb-label
        strict
        v-model="modelValueC"
        :min="min"
        :max="max"
        :step="step">
      <template #thumb-label="{modelValue}">
        <ReadableNumber :number="modelValue" :prefix="prefix"/>
      </template>
    </v-range-slider>
    <div class="d-flex justify-space-between">
      <BetterNumberInput style="min-width: 200px" density="compact" :prefix="prefix" hide-details  v-model="modelValueC[0]" :label="$t('filter.min')" type="number"/>
      <span class="px-5 align-self-center">-</span>
      <BetterNumberInput style="min-width: 200px" density="compact" :prefix="prefix" hide-details v-model="modelValueC[1]" :label="$t('filter.max')" type="number"/>
    </div>
  </div>
</template>
<script>
import BetterNumberInput from "@/components/helpers/BetterNumberInput.vue";
import ReadableNumber from "@/components/helpers/ReadableNumber.vue";

export default {
  name: 'FilterItem',
  components: {ReadableNumber, BetterNumberInput},
  props: {
    step: {
      type: Number,
      default: 10
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 1000000
    },
    modelValue: {
      type: Array,
      default: () => [0, 1000000],
    },
    label: {
      type: String,
      required: true
    },
    prefix: {
      type: String,
      default: null
    }
  },
  computed:{
    modelValueC:{
      get(){
        return this.modelValue;
      },
      set(value){
        this.$emit('update:modelValue', value);
      }
    }
  }
}
</script>