<template>
  <v-tabs v-model="tab" bg-color="transparent">
    <v-tab value="one">
      {{ $t("settingsPopup.admin.objects.verification.title") }}
    </v-tab>
    <v-tab value="two">{{
      $t("settingsPopup.admin.objects.marketing.title")
    }}</v-tab>
    <v-tab value="three">{{ $t("settingsPopup.admin.objects.success") }}</v-tab>
    <v-tab value="four">{{ $t("settingsPopup.admin.objects.error") }}</v-tab>
  </v-tabs>

  <v-window v-model="tab">
    <v-window-item value="one">
      <VerificationOverview />
    </v-window-item>

    <v-window-item value="two">
      <v-container>
        <MarketingList :objects="objectsPending" />
      </v-container>
    </v-window-item>

    <v-window-item value="three">
      <v-container>
        <MarketingList :objects="objectsSuccess" />
      </v-container>
    </v-window-item>
    <v-window-item value="four">
      <v-container>
        <MarketingList :objects="objectsCancelled" />
      </v-container>
    </v-window-item>
  </v-window>
</template>

<script>
import VerificationList from "@/components/admin/VerificationList";
import MarketingList from "@/components/admin/vermarktung/MarketingList";
import { useAdminStore } from "@/stores/admin.module";
import VerificationOverview from "@/views/verification/VerificationOverview.vue";
export default {
  name: "AdminOverview",
  components: {VerificationOverview, MarketingList, VerificationList },
  setup() {
    const adminStore = useAdminStore();
    adminStore.getPendingEstateObjects();
    adminStore.getCancelledEstateObjects();
    adminStore.getSuccessEstateObjects();
    return {
      adminStore,
    };
  },
  computed: {
    objectsPending() {
      return this.adminStore.pendingEstateObjects;
    },
    objectsCancelled() {
      return this.adminStore.cancelledEstateObjects;
    },
    objectsSuccess() {
      return this.adminStore.successEstateObjects;
    },
  },
  data() {
    return {
      tab: null,
    };
  },
};
</script>

<style scoped></style>
