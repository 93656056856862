<template>
  <v-list-item :disabled="disabled" link  :title="title">
    <template #prepend>
      <v-avatar style="border-radius:5px" color="white">
        <v-icon color="secondary" :icon="icon"></v-icon>
      </v-avatar>
    </template>
    <template #append>
      <v-icon icon="mdi-chevron-right"></v-icon>
    </template>

  </v-list-item>
</template>

<script>
export default {
  name: "ProfileSettingsItem",
  props:{
    disabled:Boolean,
    title:String,
    icon:String,
    link:String
  }
}
</script>

<style scoped>

</style>