<template>
<v-alert class="mx-2"
          type="warning"
          prominent
          border="top">
  <template #text>
    <div class="py-1 text-left">
    <span>{{$t('confirm_email.text')}}</span>
    <v-btn @click="send" size="small" color="white" class="ml-2">{{$t('confirm_email.button')}}</v-btn>
    </div>
  </template>
<template #append>

</template>
</v-alert>
</template>

<script>
import {sendToken} from "@/services/confirmation-token.service";

export default {
  name: "ConfirmationTokenBanner",
  methods:{
    send(){
      sendToken();
    }
  }
}
</script>

<style scoped>

</style>