<template>
  <v-card color="blue" rounded class="rounded-xl pa-5 d-flex flex-wrap" max-width="200px" min-height="100px"
          elevation="0">
    <v-img cover style="position:absolute;top:0;left:0;width:100%;height:100%;opacity:0.1;z-index:0"
           :src="require('@/assets/'+image)"></v-img>
    <div style="width: 100%;z-index:1" class="text-white text-left">Freier Slot</div>
    <div style="z-index:1" class="d-flex align-end justify-start">
      <div>
        <v-btn :disabled="!courseOneCompleted" variant="tonal" @click="$router.push('/immobilie/erstellen')"
               color="accent" block size="small">{{$t('object.add')}}
        </v-btn>
        <v-btn class="mt-2" small v-if="!courseOneCompleted" variant="tonal"
               @click="$router.push('/weiterbildung/sektion/1')" color="accent" block size="small">
          {{$t('education.to')}}
        </v-btn>

      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  name: 'FreeSlotCard',
  props: {
    courseOneCompleted: {},
    image: {},
    slide: {}
  }
}
</script>