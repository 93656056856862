<template>
  <div>
    <GreetingUser v-if="mobile" gk ref="dashboard-greeting"/>
    <v-container>
      <h3 class="text-left mb-5 mt-10 heading-desktop">
        {{ $t("allObjects") }}
      </h3>
      <router-view />
    </v-container>
  </div>
</template>

<script>
import GreetingUser from "@/components/dashboard/GreetingUser.vue";
import ObjectCaroussel from "@/components/objects/ObjectCaroussel.vue";

export default {
  name: "GkAdminDashboardView",
  components: { ObjectCaroussel, GreetingUser },
  computed: {
    mobile() {
      return this.$vuetify.display.mobile;
    },
  },
};
</script>

<style scoped></style>
