<template>
  <v-list-item
    class="text-left position-relative cursor-pointer"
    @mouseenter="showEditIcon = true"
    @mouseleave="showEditIcon = false"
  >
    <template v-if="!isEditing">
      <v-list-item-title @click="startEditing">
        {{ label
        }}<v-btn
          v-if="(showEditIcon || $vuetify.display.mdAndDown) && canEdit"
          size="small"
          :color="$vuetify.display.mdAndDown ? 'grey-darken-2' : 'grey'"
          variant="plain"
          icon="mdi-pencil"
          density="compact"
          class="ms-3 cursor-pointer"
        >
        </v-btn
      ></v-list-item-title>
      <v-list-item-subtitle @click="startEditing" class="d-flex align-center">
        <slot name="display"></slot>
      </v-list-item-subtitle>
    </template>
    <template v-else>
      <!-- Select Feld -->
      <v-select
        v-if="type === 'select'"
        v-model="localValue"
        :items="items"
        :item-title="items.itemTitle"
        :item-value="items.itemValue"
        :label="label"
        density="compact"
        hide-details
        variant="solo-filled"
        @update:modelValue="saveEdit"
      >
        <template v-slot:append>
          <v-btn
            variant="plain"
            size="small"
            icon="mdi-close"
            @click="cancelEdit"
          ></v-btn>
        </template>
      </v-select>
      <!-- Text Feld -->
      <v-text-field
        v-else
        v-model="localValue"
        :label="label"
        density="compact"
        :rules="[(v) => !isNaN(v) || 'Nur Zahlen erlaubt']"
        @blur="cancelEdit"
        @keyup.enter="saveEdit"
        autofocus
        variant="solo-filled"
      >
        <template v-slot:append-inner>
          <v-divider class="my-2 mr-1" inset vertical></v-divider>
          <v-btn
            variant="plain"
            size="small"
            icon="mdi-check"
            @click="saveEdit"
          ></v-btn>
          <v-btn
            variant="plain"
            size="small"
            icon="mdi-close"
            @click="cancelEdit"
          ></v-btn>
        </template>
      </v-text-field>
    </template>
  </v-list-item>
</template>

<script>
export default {
  name: "EditableListItem",
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "text",
      validator: (value) => ["text", "select"].includes(value),
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showEditIcon: false,
      isEditing: false,
      localValue: null,
      isValid: true,
    };
  },

  methods: {
    startEditing() {
      if (!this.canEdit) return;
      this.localValue = this.modelValue;
      this.isEditing = true;
      this.isValid = true;
    },
    saveEdit() {
      if (this.type !== "select") {
        this.isValid = !isNaN(this.localValue);
        if (!this.isValid) return;
      }

      if (this.localValue !== this.modelValue) {
        this.$emit("update:modelValue", this.localValue);
      }
      this.isEditing = false;
    },
    cancelEdit() {
      this.localValue = this.modelValue;
      this.isEditing = false;
      this.isValid = true;
    },
  },
};
</script>

<style scoped></style>
