<template>
  <v-card min-height="175px" rounded width="300px" @click="$router.push('/immobilie/'+object.id)" :class="{'successObject':object.state==='SUCCESS' || object.state === 'COINS_COLLECTED'}" class="rounded-xl hover-sheet ml-0 mr-0 pa-5 d-flex flex-wrap align-content-space-between" elevation="2">
    <v-img
        cover
        v-if="object.images && object.images.length > 0"
        :src="'data:image/webp;base64,'+object.images[0].file"
        style="pointer-events: none"
        class="background-image gradient-background"
        height="100%"
    />
    <v-img class="background-image gradient-background"
           v-else
           width="100%"
           height="100%"
           src="https://plus.unsplash.com/premium_photo-1680281936362-aff258ecd143?q=80&w=3100&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
           cover
    ></v-img>
    <v-list-item class="pa-0 mb-4 text-left" :title="address">
      <template #subtitle>
        <EnumDisplayLanguage
            enum-name="objectType"
            :enum-item="object.objectType"
        />
      </template>
    </v-list-item>
    <div style="position:relative;z-index:1;width:100%">
    <div class="text-left d-flex justify-start align-center">
      <v-skeleton-loader
          :loading="!sale"
          type="text"
          width="50px"
          color="transparent"
          class="pa-0 ma-0 simple-loader"
      >
        <v-tooltip bottom>
          <template #activator="{ props }">
            <v-chip color="green" variant="outlined" v-bind="props">
              <ReadableNumber :number="calculatedTipsterProvision" /> €
            </v-chip>
          </template>
          {{ $t("calculatedReferralFee") }}
        </v-tooltip>

      </v-skeleton-loader>
        <!--<v-dialog max-width="500px">
        <template #activator="{props}">
          <v-badge
             class="pa-0 ml-1 badge-no-padding"
             offset-x="5"
              v-bind="props"
              color="accent"
              content="?"
              inline
          ></v-badge>
        </template>
       <v-card>
         <v-card-text>
            {{$t('object.fairValueExpectedExplanation')}}
         </v-card-text>
       </v-card>
      </v-dialog>-->
      <v-chip :color="verificationStateColor" class="mx-2">
        <EnumDisplayLanguage
            enum-name="bToCState"
            :enum-item="object.state"
        />
      </v-chip>
    </div>
      <div style="width:100%" class="d-flex justify-space-between">
        <!--<v-btn size="x-small" variant="tonal" icon color="red"><v-icon>mdi-delete</v-icon></v-btn>-->
      </div>
    </div>
  </v-card>
</template>

<script>
import {getObjectName, getSale} from "@/services/object.service";
import {getVerificationState} from "@/services/requirements.service";
import ReadableNumber from "@/components/helpers/ReadableNumber.vue";
import EnumDisplayLanguage from "@/components/helpers/EnumDisplayLanguage.vue";

export default {
  name: "ObjectDisplaySmall",
  components: {EnumDisplayLanguage, ReadableNumber},

  data(){
    return{
      sale:null,
      verificationState:null
    }
  },
  props:{
    object:Object
  },
  created(){
    this.getSaleObject(this.object.id);
    this.getVerificationState(this.object.id);
  },
  methods:{
    async getSaleObject(id){
      if (id) this.sale = await getSale(id);
      else this.$router.push("/");
    },
    async getVerificationState(id){
      if(id) this.verificationState= await getVerificationState(id);
    }
  },
  computed:{
    stateDisplay(){
      if(!this.verificationState) return null;
      let objectState= this.object.state;
      console.log(objectState);
      if(objectState==='SUCCESSS') return this.$t('process.collectCoins')
      else if(objectState==='COINS_COLLECTED') return this.$t('process.success')
      return this.verificationState;
    },
    verificationStateColor(){
      let state = this.verificationState;
      if(state==='verifiziert') return 'success';
      else if(state==='Verifizierung läuft') return 'warning';
      else if(state==='Verifizierung fehlgeschlagen.') return 'red darken-5'
      else return 'error'
    },
    calculatedTipsterProvision(){
      if(this.sale.actualValue>0) return Math.floor((this.sale.actualValue*(this.sale.provision/100))*(this.sale.tipsterProvision/100));
      else return Math.floor((this.sale.value*(this.sale.provision/100))*(this.sale.tipsterProvision/100));
    },
    coins(){
      if(!this.sale) return null;
      if(this.sale.actualValue>0) return new Intl.NumberFormat('en-DE').format(this.sale.actualValue);
      else return new Intl.NumberFormat('en-DE').format(this.sale.value);
    },
    address(){
      return this.object.address.postcode+' '+this.object.address.city+' '+this.object.address.street;
    },
    objectName(){
      return getObjectName(this.object);
    }
  }
}
</script>

<style scoped>
.background-image{
  position: absolute;
  top: 0;
  left: 0;
  z-index:0;
  width: 100%;
  height: 100%;
}
.successObject{
  border:2px solid goldenrod;
}
.hover-sheet {
  cursor: pointer;
  box-sizing: border-box!important;
}
.hover-sheet:hover {
  outline: 1px solid #cca876;
}
</style>