<script>
import {getVerifications} from "@/services/verification.service";

export default {
  name: "VerificationOverview",
  data(){
    return{
      verifications: [],
      loading:false
    }
  },
  created() {
    this.getVerifications();
  },
  methods:{
    async getVerifications() {
  this.loading = true;
  try {
    this.verifications = await getVerifications();
  } catch (error) {
    console.error("Error fetching verifications:", error);
  } finally {
    this.loading = false;
  }
}
  }
}
</script>

<template>
  <v-container class="d-flex flex-wrap ga-5">
    <h3 class="mt-10 text-left w-100">Ausstehende Verifizierungen</h3>
    <div>
      <v-skeleton-loader v-if="loading" type="list-item@10" class="w-100" />
    </div>
    <div class="w-100">
      <v-list-item elevation="2" class="bg-secondary w-100 pa-5 align-start text-left"
                   v-for="verification of verifications"  :to="'/admin/verification/'+verification.objectId" :title="verification.name" :subtitle="verification.status">
      </v-list-item>
    </div>
    <div class="w-100" v-if="!verifications || verifications.length<=0">
      <p class="text-center" type="error">{{ $t("noData.default") }}</p>
    </div>

  </v-container>
</template>

<style scoped>

</style>