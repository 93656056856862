// Pinia Store
import { defineStore } from 'pinia'
import {getRequirements} from "@/services/requirements.service";
import {getStates} from "@/services/state.service";
import {getAllUsersObjects} from "@/services/object.service";

const state = () => ({
    requirements:null,
    states:null,
    myObjects:[],
    loading:true,

});
export const useObjectStore = defineStore('objectStore',{
    state: state,



// getters
    getters: {

    },

// actions
    actions:{
        async updateRequirements(id) {
            this.requirements = await getRequirements(id);
        },
        async updateStates(id){
            this.states= await getStates(id);
        },
        async getMyObjects() {
            this.loading=true;
            this.myObjects = await getAllUsersObjects().finally(() => this.loading = false);
            this.loading=false;
        }
    }
});